/**
 * @generated SignedSource<<a778d1ffcc01668f2c05ca2a8882956a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SOLANA" | "SOLDEV" | "SONEIUM_MINATO" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useItemCardCta_item$data = {
  readonly __typename: string;
  readonly chain: {
    readonly identifier: ChainIdentifier;
    readonly isTradingEnabled: boolean;
  };
  readonly isCurrentlyFungible?: boolean;
  readonly isDelisted?: boolean;
  readonly isListable?: boolean;
  readonly orderData: {
    readonly bestAskV2: {
      readonly maker: {
        readonly address: string;
      };
      readonly orderType: OrderV2OrderType;
    } | null;
  };
  readonly ownedQuantity?: string | null;
  readonly " $fragmentType": "useItemCardCta_item";
};
export type useItemCardCta_item$key = {
  readonly " $data"?: useItemCardCta_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useItemCardCta_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useItemCardCta_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTradingEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "maker",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDelisted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isListable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        {
          "condition": "showContextMenu",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "identity",
                  "variableName": "identity"
                }
              ],
              "kind": "ScalarField",
              "name": "ownedQuantity",
              "storageKey": null
            }
          ]
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "f438788e863232b614973649cc100c9a";

export default node;
